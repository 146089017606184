export const BoxShadowFields = {
    createFields(defaults = {}) {
        let defaultFields = {
            enabled: false,
            horizontalOffset: 0,
            verticalOffset: 0,
            blurRadius: 0,
            spreadRadius: 0,
            color: '#000000',
        }
        defaultFields = Object.assign(defaultFields, defaults)
        return {
            boxShadow: {
                content: {
                    enabled: {
                        content: 'toggle',
                        default: defaultFields.enabled,
                    },
                    horizontalOffset: {
                        content: 'number',
                        default: defaultFields.horizontalOffset,
                        unit: 'px',
                    },
                    verticalOffset: {
                        content: 'number',
                        default: defaultFields.verticalOffset,
                        unit: 'px',
                    },
                    blurRadius: {
                        content: 'number',
                        default: defaultFields.blurRadius,
                        unit: 'px',
                    },
                    spreadRadius: {
                        content: 'number',
                        default: defaultFields.spreadRadius,
                        unit: 'px',
                    },
                    color: {
                        content: 'color',
                        default: defaultFields.color,
                    },
                }
            },
        }
    },
    createPartialCSS(prefix = '') {
        return `
            box-shadow: {${prefix}boxShadow.enabled ? {${prefix}boxShadow.horizontalOffset}{${prefix}boxShadow.unit} {${prefix}boxShadow.verticalOffset}{${prefix}boxShadow.unit} {${prefix}boxShadow.blurRadius}{${prefix}boxShadow.unit} {${prefix}boxShadow.spreadRadius}{${prefix}boxShadow.unit} {${prefix}boxShadow.color} : 'none'};
        `
    }
}