import {BorderOptions} from '../options/BorderOptions'

export const BorderFields = {
    createFields(defaults = {}) {
        let defaultFields = {
            width: 0,
            style: 'none',
            color: '#000000',
            borderRadius: 0,
        }
        defaultFields = Object.assign(defaultFields, defaults)
        return {
            border: {
                content: {
                    width: {
                        content: 'number',
                        default: defaultFields.width,
                        unit: 'px',
                    },
                    style: {
                        content: 'select',
                        default: defaultFields.style,
                        options: BorderOptions
                    },
                    color: {
                        content: 'color',
                        default: defaultFields.color,
                    },
                    borderRadius: {
                        content: 'number',
                        default: defaultFields.borderRadius,
                        unit: 'px',
                        min: 0,
                        max: 9999,
                        step: 1,
                    }
                }
            },
        }
    },
    createPartialCSS(prefix = '') {
        return `
            border-width: {${prefix}border.width}{${prefix}border.unit};
            border-style: {${prefix}border.style};
            border-color: {${prefix}border.color};
            border-radius: {${prefix}border.borderRadius}{${prefix}border.unit};
        `
    }
}