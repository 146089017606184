import {FlexBoxAlignmentOptions, FlexBoxWrapOptions} from '../options/SelectionOptions'

export const FlexBox = {
    createFields(defaults = {}) {
        let defaultFields = {
            padding: 20,
            margin: 10,
            alignment: 'center',
            wrap: 'wrap',
            width: '250px',
            breakpointA: {
                minWidth: 576,
                howManyColumns: 1,
            },
            breakpointB: {
                minWidth: 768,
                howManyColumns: 2,
            },
            breakpointC: {
                minWidth: 992,
                howManyColumns: 3,
            }
        }
        let fields = Object.assign(defaultFields, defaults)
        return {
            grid: {
                content: {
                    padding: {
                        content: 'number',
                        default: fields.padding,
                        unit: 'px',
                    },
                    margin: {
                        content: 'number',
                        default: fields.margin,
                        unit: 'px',
                    },
                    alignment: {
                        content: 'select',
                        default: fields.alignment,
                        options: FlexBoxAlignmentOptions
                    },
                    wrap: {
                        content: 'select',
                        default: fields.wrap,
                        options: FlexBoxWrapOptions
                    },
                    width: {
                        content: 'text',
                        default: fields.width,
                    },
                    breakpointA: {
                        content: {
                            minWidth: {
                                content: 'number',
                                default: fields.breakpointA.minWidth,
                                min: 1,
                                unit: 'px',
                            },
                            howManyColumns: {
                                content: 'number',
                                default: fields.breakpointA.howManyColumns,
                                min: 1,
                                step: 1,
                                description: 'How many columns should be displayed',
                            }
                        }
                    },
                    breakpointB: {
                        content: {
                            minWidth: {
                                content: 'number',
                                default: fields.breakpointB.minWidth,
                                min: 1,
                                unit: 'px',
                            },
                            howManyColumns: {
                                content: 'number',
                                default: fields.breakpointB.howManyColumns,
                                min: 1,
                                step: 1,
                                description: 'How many columns should be displayed',
                            }
                        }
                    },
                    breakpointC: {
                        content: {
                            minWidth: {
                                content: 'number',
                                default: fields.breakpointC.minWidth,
                                min: 1,
                                unit: 'px',
                            },
                            howManyColumns: {
                                content: 'number',
                                default: fields.breakpointC.howManyColumns,
                                min: 1,
                                step: 1,
                                description: 'How many columns should be displayed',
                            }
                        }
                    }
                }
            }
        }
    },
    createCSS: (rowSelector = 'sc-row', columnSelector = 'sc-column') => {
        return `
            {target} .${rowSelector} {
                display: flex;
                flex-wrap: wrap;                 
                justify-content: {grid.alignment};
            }
  
            {target} .${columnSelector} {
                  flex-basis: calc((100%/{grid.breakpointC.howManyColumns}) - {grid.padding});
                  padding: {grid.padding};
                  margin: {grid.margin};
                  width: {grid.width}px;
                  box-sizing: border-box;
            }
            
            @media (min-width: {grid.breakpointA.minWidth}) {
                {target} .${columnSelector} { flex-basis: calc((100%/{grid.breakpointA.howManyColumns}) - {grid.padding}); }
            }
            @media (min-width: {grid.breakpointB.minWidth}) {
                {target} .${columnSelector} { flex-basis: calc((100%/{grid.breakpointB.howManyColumns}) - {grid.padding}); }
            }
            @media (min-width: {grid.breakpointC.minWidth}) {
                {target} .${columnSelector} { flex-basis: calc((100%/{grid.breakpointC.howManyColumns}) - {grid.padding}); }
            }
        `
    }
}